import axios from 'axios';
import { useNavigate } from 'react-router-dom';
//export const API_BASE_URL = 'http://127.0.0.1:8000';
export const API_BASE_URL = 'https://api.balade-parfumee.xyz/public';
export const API_PREFIX = 'api';

function getBearerToken() {
  const token = localStorage.getItem('token');
  return `Bearer ${token}`;
}
export async function iSConnected() {
  let obj = {
    headers: {
      Authorization: getBearerToken(),
    },
  };
  return axios
    .get(`${API_BASE_URL}/${API_PREFIX}/iSConnected`, obj)
    .then((res) => res.data)
    .catch((err) => console.log(err));
}
export async function getAllFragrance() {
  let obj = {
    headers: {
      Authorization: getBearerToken(),
    },
  };
  return axios
    .get(`${API_BASE_URL}/api/fragrance`, obj)
    .then((res) => res.data)
    .catch((err) => console.log(err));
}

export async function register(body) {
  return axios.post(`${API_BASE_URL}/register`, body);
}

export async function generateCode(body) {
  return axios.post(`${API_BASE_URL}/api/auth/generate_code`, body);
}

export async function verifyCode(body) {
  return axios.post(`${API_BASE_URL}/api/auth/verify_code`, body);
}

export async function getInvoices() {
  let obj = {
    headers: {
      Authorization: getBearerToken(),
    },
  };
  return axios
    .get(`${API_BASE_URL}/api/getInvoice`, obj)
    .then((res) => res.data)
    .catch((err) => console.log(err));
}

export async function readAnNotification(params) {
  let obj = {
    headers: {
      Authorization: getBearerToken(),
    },
  };
  return axios
    .put(`${API_BASE_URL}/api/read/notification`, params, obj)
    .then((res) => res.data)
    .catch((err) => console.log(err));
}

export async function checkPhoneExit(params) {
  return axios
    .post(`${API_BASE_URL}/checkPhone`, params)
    .then((res) => res.data)
    .catch((err) => console.log(err));
}
export async function getCurrentSubscription() {
  let obj = {
    headers: {
      Authorization: getBearerToken(),
    },
  };
  return axios
    .get(`${API_BASE_URL}/api/getCurrentSubscription`, obj)
    .then((res) => res.data)
    .catch((err) => console.log(err));
}

export async function getInfoCardBank() {
  let obj = {
    headers: {
      Authorization: getBearerToken(),
    },
  };
  return axios
    .get(`${API_BASE_URL}/api/infoCreditCard`, obj)
    .then((res) => res.data)
    .catch((err) => console.log(err));
}

export async function cancelSubscription(body) {
  let obj = {
    headers: {
      Authorization: getBearerToken(),
    },
  };
  return axios
    .put(`${API_BASE_URL}/api/cancelSubscription`, body, obj)
    .then((res) => res.data)
    .catch((err) => err);
}
export async function changeSubscription(body) {
  let obj = {
    headers: {
      Authorization: getBearerToken(),
    },
  };
  return axios
    .put(`${API_BASE_URL}/api/changeSubscription`, body, obj)
    .then((res) => res.data)
    .catch((err) => err);
}

export async function changeCardPayment(body) {
  let obj = {
    headers: {
      Authorization: getBearerToken(),
    },
  };
  return axios
    .put(`${API_BASE_URL}/api/updateCreditCard`, body, obj)
    .then((res) => res.data)
    .catch((err) => err);
}

export async function getWishlist() {
  let obj = {
    headers: {
      Authorization: getBearerToken(),
    },
  };
  return axios
    .get(`${API_BASE_URL}/${API_PREFIX}/wishlist`, obj)
    .then((res) => res.data)
    .catch((err) => console.log(err));
}
export async function addWishlist(frangrance = '') {
  let obj = {
    headers: {
      Authorization: getBearerToken(),
    },
  };
  frangrance = frangrance ? '/' + frangrance : '';
  return axios.post(`${API_BASE_URL}/${API_PREFIX}/wishlist${frangrance}`, {}, obj);
}
export async function deleteWishlist(wishlist) {
  let obj = {
    headers: {
      Authorization: getBearerToken(),
    },
  };
  return axios.delete(`${API_BASE_URL}/${API_PREFIX}/wishlist/${wishlist}`, obj);
}
export async function updateWishlist(wishlist, fragrance) {
  let obj = {
    headers: {
      Authorization: getBearerToken(),
    },
  };
  return axios.put(`${API_BASE_URL}/${API_PREFIX}/wishlist/${wishlist}/${fragrance}`, {}, obj);
}
export async function authLogin(body) {
  return axios.post(`${API_BASE_URL}/${API_PREFIX}/auth/login`, body);
}

export async function lostPassword(body) {
  return axios.post(`${API_BASE_URL}/${API_PREFIX}/auth/request-password-reset`, body);
}

export async function resetPassword(body) {
  return axios.post(`${API_BASE_URL}/${API_PREFIX}/auth/reset-password`, body);
}

// ficheEsais
export async function getFicheEssais() {
  let obj = {
    headers: {
      Authorization: getBearerToken(),
    },
  };
  return axios
    .get(`${API_BASE_URL}/${API_PREFIX}/perfumeTrialSheet`, obj)
    .then((res) => res.data)
    .catch((err) => console.log(err));
}
export async function deleteFicheEssais(ficheEssais) {
  let obj = {
    headers: {
      Authorization: getBearerToken(),
    },
  };
  return axios.delete(`${API_BASE_URL}/${API_PREFIX}/perfumeTrialSheet/${ficheEssais}`, obj);
}
export async function updateFicheEssais(ficheEssais, body, fragrance = false) {
  let obj = {
    headers: {
      Authorization: getBearerToken(),
    },
  };
  try {
    const res = await axios.put(
      `${API_BASE_URL}/${API_PREFIX}/perfumeTrialSheet/${ficheEssais}/${fragrance}`,
      body,
      obj,
    );
    return res;
  } catch (error) {
    return error;
  }
}
export async function addFicheEssais(selectDate) {
  let obj = {
    headers: {
      Authorization: getBearerToken(),
    },
  };
  return axios.post(`${API_BASE_URL}/${API_PREFIX}/perfumeTrialSheet/${selectDate}`, {}, obj);
}
// profil
export async function feltOnMyCollection(body) {
  let obj = {
    headers: {
      Authorization: getBearerToken(),
    },
  };
  return axios.put(`${API_BASE_URL}/${API_PREFIX}/profil/feltOnMyCollection`, body, obj);
}
export async function getCheckList() {
  let obj = {
    headers: {
      Authorization: getBearerToken(),
    },
  };
  return axios
    .get(`${API_BASE_URL}/${API_PREFIX}/checkList`, obj)
    .then((res) => res.data)
    .catch((err) => {
      if (redirect(err?.response?.data?.code)) {
        window.location.href = redirect(err?.response?.data?.code);
      }
    });
}
export async function removeCheckList(id) {
  let obj = {
    headers: {
      Authorization: getBearerToken(),
    },
  };
  return axios.delete(`${API_BASE_URL}/${API_PREFIX}/checkList/delete/${id}`, obj);
}

export async function updateCheckList(checkList, state = false, fragrance = false) {
  let obj = {
    headers: {
      Authorization: getBearerToken(),
    },
  };
  fragrance = fragrance ? '/' + fragrance : '';
  return axios.put(
    `${API_BASE_URL}/${API_PREFIX}/checkList/${checkList}${fragrance}`,
    { state },
    obj,
  );
}
export async function addCheckList(selectDate) {
  let obj = {
    headers: {
      Authorization: getBearerToken(),
    },
  };
  return axios.post(`${API_BASE_URL}/${API_PREFIX}/checkList/${selectDate}`, {}, obj);
}

// reviewPerfumeNote
export async function getReviewPerfumeNote() {
  let obj = {
    headers: {
      Authorization: getBearerToken(),
    },
  };
  return axios
    .get(`${API_BASE_URL}/${API_PREFIX}/reviewPerfumeNote`, obj)
    .then((res) => res.data)
    .catch((err) => console.log(err));
}
export async function addReviewPerfumeNote(selectDate) {
  let obj = {
    headers: {
      Authorization: getBearerToken(),
    },
  };
  return axios.post(`${API_BASE_URL}/${API_PREFIX}/reviewPerfumeNote/${selectDate}`, {}, obj);
}

export async function updateReviewPerfumeNote(id, body) {
  let obj = {
    headers: {
      Authorization: getBearerToken(),
    },
  };
  return axios.put(`${API_BASE_URL}/${API_PREFIX}/reviewPerfumeNote/${id}`, body, obj);
}
// api/reviewPerfumeNoteRemoveFragrance/{reviewPerfumeNote}/{fragrances}
export async function reviewPerfumeNoteRemoveFragrance(reviewPerfumeNote, idFragrance) {
  let obj = {
    headers: {
      Authorization: getBearerToken(),
    },
  };
  return axios.delete(
    `${API_BASE_URL}/${API_PREFIX}/reviewPerfumeNoteRemoveFragrance/${reviewPerfumeNote}/${idFragrance}`,
    obj,
  );
}
export async function reviewPerfumeNoteAddFragrance(reviewPerfumeNote, idFragrance) {
  let obj = {
    headers: {
      Authorization: getBearerToken(),
    },
  };
  return axios.post(
    `${API_BASE_URL}/${API_PREFIX}/reviewPerfumeNoteAddFragrance/${reviewPerfumeNote}/${idFragrance}`,
    {},
    obj,
  );
}
export async function deleteReviewPerfumeNote(id) {
  let obj = {
    headers: {
      Authorization: getBearerToken(),
    },
  };
  return axios.delete(`${API_BASE_URL}/${API_PREFIX}/reviewPerfumeNote/${id}`, obj);
}
export async function addFragranceInReviewPerfumeNote(idFragrance) {
  let obj = {
    headers: {
      Authorization: getBearerToken(),
    },
  };
  return axios.post(`${API_BASE_URL}/${API_PREFIX}/reviewPerfumeNote/${idFragrance}`, {}, obj);
}

// api/layerings
export async function getLayerings() {
  let obj = {
    headers: {
      Authorization: getBearerToken(),
    },
  };
  return axios
    .get(`${API_BASE_URL}/${API_PREFIX}/layerings`, obj)
    .then((res) => res.data)
    .catch((err) => console.log(err));
}

export async function addLayerings() {
  let obj = {
    headers: {
      Authorization: getBearerToken(),
    },
  };
  return await axios.post(`${API_BASE_URL}/${API_PREFIX}/layerings`, {}, obj);
}

//     #[Route('api/layerings/{layering}', name: 'app_update_Layerings', methods: "PUT")]
export async function updateLayerings(id, body) {
  let obj = {
    headers: {
      Authorization: getBearerToken(),
    },
  };
  return axios
    .put(`${API_BASE_URL}/${API_PREFIX}/layerings/${id}`, body, obj)
    .then((res) => res.data)
    .catch((err) => console.log(err));
}
export async function deleteLayerings(id) {
  let obj = {
    headers: {
      Authorization: getBearerToken(),
    },
  };
  return axios
    .delete(`${API_BASE_URL}/${API_PREFIX}/layerings/${id}`, obj)
    .then((res) => res.data)
    .catch((err) => console.log(err));
}
export async function getProfil() {
  let obj = {
    headers: {
      Authorization: getBearerToken(),
    },
  };
  return axios
    .get(`${API_BASE_URL}/${API_PREFIX}/profil`, obj)
    .then((res) => res.data)
    .catch((err) => console.log(err));
}

export async function getNotifications() {
  let obj = {
    headers: {
      Authorization: getBearerToken(),
    },
  };
  try {
    return axios
      .get(`${API_BASE_URL}/${API_PREFIX}/users/getAllNotification`, obj)
      .then((res) => res.data)
      .catch((err) => console.log(err));
  } catch (error) {
    return error;
  }
}

export async function getNotificationIsRead() {
  let obj = {
    headers: {
      Authorization: getBearerToken(),
    },
  };
  try {
    return axios
      .get(`${API_BASE_URL}/${API_PREFIX}/isRead/notification`, obj)
      .then((res) => res.data)
      .catch((err) => console.log(err));
  } catch (error) {
    return error;
  }
}

export async function updateMailChimpTagUser(body) {
  let obj = {
    headers: {
      Authorization: getBearerToken(),
    },
  };
  return axios
    .post(`${API_BASE_URL}/${API_PREFIX}/changeTagMailChimp`, body, obj)
    .then((res) => res.data)
    .catch((err) => console.log(err));
}

export async function updateProfilUser(body) {
  let obj = {
    headers: {
      Authorization: getBearerToken(),
    },
  };
  return axios
    .post(`${API_BASE_URL}/${API_PREFIX}/update-user`, body, obj)
    .then((res) => res.data)
    .catch((err) => console.log(err));
}

export async function getContentExclusive(body) {
  let obj = {
    headers: {
      Authorization: getBearerToken(),
    },
  };
  return axios
    .get(`${API_BASE_URL}/${API_PREFIX}/contentExclusive?page=1&limit=10`, obj)
    .then((res) => res.data)
    .catch((err) => console.log(err));
}

export async function profilEdit(body) {
  let obj = {
    headers: {
      Authorization: getBearerToken(),
    },
  };
  return axios.put(`${API_BASE_URL}/${API_PREFIX}/profilPUT`, body, obj);
}
export async function add_tag(params) {
  let obj = {
    headers: {
      Authorization: getBearerToken(),
    },
  };
  return axios.post(`${API_BASE_URL}/${API_PREFIX}/profil/tag`, params, obj);
}
export async function getTag() {
  let obj = {
    headers: {
      Authorization: getBearerToken(),
    },
  };
  return axios
    .get(`${API_BASE_URL}/${API_PREFIX}/profil/tag`, obj)
    .then((res) => res.data)
    .catch((err) => console.log(err));
}
export async function edit_tag(id, body) {
  let obj = {
    headers: {
      Authorization: getBearerToken(),
    },
  };
  return axios.put(`${API_BASE_URL}/${API_PREFIX}/profil/tag/${id}`, body, obj);
}
export async function remove_tag(id) {
  let obj = {
    headers: {
      Authorization: getBearerToken(),
    },
  };
  return axios.delete(`${API_BASE_URL}/${API_PREFIX}/profil/tag/${id}`, obj);
}
export function redirect(code) {
  if (code === 401) {
    return '/authentification';
  } else if (code === 400) {
    return '/authentification';
  }
  return false;
}
