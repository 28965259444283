import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import perfumesComponents from 'src/assets/images/login/perfumes_components.png';
import { resetPassword } from 'src/request';

const ChangePwd = () => {
  const [validationCode, setValidationCode] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validationCode) {
      setErrorMessage('Veuillez saisir le code de validation.');
      return;
    }

    if (password !== confirmPassword) {
      setErrorMessage('Les mots de passe ne correspondent pas');
      return;
    }

    setLoading(true);

    try {
      const response = await resetPassword({ code: validationCode, password });

      if (response.success === false && response.message === 'Code has expired') {
        setErrorMessage('Le code de validation a expiré.');
      } else {
        setSuccessMessage('Mot de passe réinitialisé avec succès');
        setTimeout(() => navigate('/authentification'), 3000); // Redirige vers la page de connexion après 3 secondes
      }
    } catch (error) {
      if (!!error?.response?.data?.message) {
        setErrorMessage('Le code de validation a expiré.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={styles.resetPasswordContainer}>
      <div style={styles.formContainer}>
        <h1 style={styles.title}>Réinitialiser mon mot de passe</h1>
        <p style={styles.description}>
          Un code de validation a été envoyé par email. Veuillez saisir ce code ainsi que votre
          nouveau mot de passe.
        </p>
        <form onSubmit={handleSubmit}>
          <div style={styles.inputGroup}>
            <input
              type="text"
              placeholder="Code de validation"
              value={validationCode}
              onChange={(e) => setValidationCode(e.target.value)}
              required
              style={styles.input}
            />
          </div>
          <div style={styles.inputGroup}>
            <input
              type="password"
              placeholder="Nouveau mot de passe"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              style={styles.input}
            />
          </div>
          <div style={styles.inputGroup}>
            <input
              type="password"
              placeholder="Confirmer le mot de passe"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              style={styles.input}
            />
          </div>
          {errorMessage && <p style={styles.errorMessage}>{errorMessage}</p>}
          {successMessage && <p style={styles.successMessage}>{successMessage}</p>}
          <button type="submit" style={styles.submitButton} disabled={loading}>
            {loading ? 'Chargement...' : 'Réinitialiser le mot de passe'}
          </button>
        </form>
        <p style={styles.loginLink} onClick={() => navigate('/authentification')}>
          Retour à la connexion
        </p>
      </div>
    </div>
  );
};

const styles = {
  resetPasswordContainer: {
    display: 'flex',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageContainer: {
    flex: 1,
    height: '100%',
    overflow: 'hidden',
  },
  backgroundImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  formContainer: {
    flex: 1,
    maxWidth: '400px',
    padding: '20px',
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    marginBottom: '16px',
    fontSize: '24px',
    fontWeight: 'bold',
  },
  description: {
    marginBottom: '16px',
    fontSize: '16px',
    color: '#666',
  },
  inputGroup: {
    width: '100%',
    marginBottom: '16px',
  },
  input: {
    width: '100%',
    padding: '12px',
    border: '1px solid #ddd',
    borderRadius: '4px',
    fontSize: '16px',
  },
  errorMessage: {
    color: 'red',
    marginBottom: '16px',
  },
  successMessage: {
    color: 'green',
    marginBottom: '16px',
  },
  submitButton: {
    width: '100%',
    padding: '12px',
    backgroundColor: '#22a18a',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    fontSize: '16px',
    cursor: 'pointer',
  },
  loginLink: {
    marginTop: '16px',
    color: '#22a18a',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
};

export default ChangePwd;
